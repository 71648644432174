:root {
  --dal-elements-bg: #FFF;
}

$elements_bg: var(--dal-elements-bg);

.dal_login_container {
  position:relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  .dal_dropdown_container {
    min-width: 100%;
    position: absolute;
    top: 100%;
    left:0;
    z-index: 100;
    background: $elements_bg;
    padding: 10px 20px;
    li {
      white-space:nowrap;
    }
  }
  .dal_dropdown_trigger {
    cursor:pointer;
    padding: 10px;
  }
  .dal_hidden {
    display: none;
  }
}

.dal_login_container_mobile {
  position:relative;
  .dal_dropdown_container {
    position: relative;
    background: $elements_bg;
    padding: 10px;
  }
  .dal_hidden {
    display: none;
  }
}